<template>
	<div class="seleAddress-modal">
		<a-spin :spinning="loading">
			<a-checkbox-group :value="modalData.currentAddress" style="width: 100%;" @change="onChange">
				<a-row>
					<a-col :span="6" style="margin: 8px 0;" v-for="item in provinceList" :key="item.id">
						<a-checkbox :value="item.name"
							:disabled="modalData.addressList.includes(item.name) && !modalData.currentAddress.includes(item.fullname)"
							@click="onClick(item.name)">
							{{item.name}}
						</a-checkbox>
					</a-col>
				</a-row>
			</a-checkbox-group>
		</a-spin>
	</div>
</template>

<script>
	import {
		getDivision
	} from "@/service/modules/system";
	export default {
		props: {
			modalData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				loading: false,
				provinceList: []
			}
		},
		created() {
			this.getDivision();
		},
		methods: {
			async getDivision() {
				this.loading = true;
				try {
					let ret = await getDivision({});
					this.loading = false;
					if (ret.code === 200) {
						this.provinceList = ret.data.filter(function(item) {
							item.sub = ret.data.filter(function(childItem) {
								return (item.id == childItem.parentId)
							})
							return item.level == 0;
						});
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onChange: function(e) {
				this.modalData.currentAddress = e;
			},
			onClick: function(name) {
				this.modalData.addressList.forEach((item, index) => {
					if (item === name) {
						this.modalData.addressList.splice(index, 1);
						if (!this.modalData.newAddress.includes(name)) {
							this.modalData.newAddress.push(name);
						}
					}
				})
			}
		}
	}
</script>
