<template>
	<div>
		<a-spin :spinning="loading" size="large">
			<a-form form="form" name="form"
              :labelCol="{span: 3 }"
              :wrapperCol="{span: 20 }"
      >

				<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					商城运费设置</h4>

        <a-form-item label="默认运费" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="若未配置用户收货地址运费则使用默认运费">
          <a-input-number min="0" :precision="2" style="width: 200px;" v-model:value="postFee" placeholder="请输入包邮金额"></a-input-number>
        </a-form-item>

				<a-form-item v-bind="formItemLayout" label="配送区域设置">
					<a-table :columns="columns" :dataSource="list" :pagination="false" bordered>
						<template #bodyCell="{column, record, index }">
							
							<template v-if="column.key === 'region-slot'">
								<div
									style="width: 85%;padding: 5px;margin: 0 auto;border: solid 1px #eee; border-radius: 5px;background: #fff;"
									:style="`height: ${record.region ? 'auto' : '33px'};`"  @click="openModal(record, index)">
									{{record.region}}
								</div>
							</template>
							
							<template v-if="column.key === 'postFee-slot'">
								<a-input-number :min="0" :precision="2" v-model:value="record.postFee" />
							</template>
							
							<template v-if="column.key === 'active-slot'">
								<a-button type="danger" @click="delRow(record, index)">删除</a-button>
							</template>
						</template>

						<template #footer>
							<a-button type="primary" @click="addAddress">添加配送地区</a-button>
						</template>
					</a-table>
				</a-form-item>

				<a-row style="margin-bottom: 20px;">
					<a-col :offset="3">
						<div style="color: #c2c2c2;">
              运费费用：没配置的地区按默认运费收费，达到免运费要求时不收取运费。
						</div>
					</a-col>
				</a-row>

				<a-form-item :wrapper-col="{ span: 12, offset: 6 }">
					<a-button v-permission="['mall_freight_freight_edit']" type="primary" @click="onSubmit" :loading="loading">提交</a-button>
				</a-form-item>
			</a-form>

			<a-modal :closable="false" title="选择地区" width="800px" :maskClosable="false" v-model:visible="modalVisible">
				<addressModal :modalData="modalData"></addressModal>
				<!-- <component v-bind:is="modalComponent" :ref="modalComponent" :modalData="modalData"></component> -->
				<template #footer>
					<a-button @click="onCancel">取消</a-button>
					<a-button @click="onOK" type="primary">确定</a-button>
				</template>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import addressModal from './address-modal.vue';
	import { getFeeSettingDetail, updateFeeSetting } from '@/service/modules/mall.js';
	export default {
		components: {addressModal},
		data() {
			return {
				loading: false,
				formItemLayout: {
					labelCol: {
						span: 3
					},
					wrapperCol: {
						span: 14
					},
				},
        postFee: 0,
				columns: [{
					align: "center",
					title: "配送至",
					key: 'region-slot'
				}, {
					align: "center",
					title: "运费费用/元",
					key: 'postFee-slot'
				}, {
					align: "center",
					title: "操作",
					key: 'active-slot'
				}],
				list: [],

				modalVisible: false,
				modalData: {
					addressList: [],
					currentAddress: [],
					newAddress: []
				},
				row: { // 点击打开弹窗对象
					row: {},
					index: 0
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			// 提交动作
			onSubmit: function(e) {
				e.preventDefault();
				// 触发校验器并滚动至校验不通过的位置
        let data = {
          postFee: this.postFee,
          regionList: this.list
        }
				this.postData(data);
			},
			// 获取数据
			getData: async function() {
				this.loading = true;
				try {
					let result = await getFeeSettingDetail({});
					this.loading = false;
					if (result.code === 200) {
						let data = [];
						if (result.data.regionList) {
							// result.data.regionList.reverse();
							data = [...data, ...result.data.regionList];
							result.data.regionList.forEach((e) => {
								e.region.split("、").forEach((r) => {
									this.modalData.addressList.push(r);
								});
							});
						}
						this.postFee = result.data.postFee;
						this.list = data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			// 发送请求
			postData: async function(data) {
				//console.log('Request data: ', data);
				this.loading = true;
				try {
					let ret = await updateFeeSetting(data);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('编辑成功');
						this.getData();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			//添加配送地区
			addAddress: function() {
				let obj = {
					region: "",
					orderFee: 0,
					postFee: 0
				};
				this.list.push(obj);
			},
			//删除行
			delRow: function(row, index) {
				if (row.region) {
					let arr = row.region.split("、");
					arr.forEach((arrItem) => {
						this.modalData.addressList.forEach((item, i) => {
							if (item === arrItem) {
								this.modalData.addressList.splice(i, 1);
							}
						});
					})
				}
				this.list.splice(index, 1);
			},
			// 点击打开弹窗
			openModal: function(row, index) {
				this.row.row = row;
				this.row.index = index;
				this.modalData.currentAddress = row.region ? row.region.split("、") : [];
				this.modalData.newAddress = [];
				this.modalVisible = true;
			},
			onCancel: function() {
				this.modalVisible = false;
				this.modalData.addressList = this.modalData.addressList.concat(this.modalData.newAddress);
			},
			onOK: function() {
				this.modalVisible = false;
				var address = JSON.parse(JSON.stringify(this.modalData.currentAddress));
				this.modalData.currentAddress = [];
				this.row.row.region = address.join("、");
				this.list.splice(this.row.index, 1, this.row.row);
				this.modalData.addressList = [...new Set(this.modalData.addressList.concat(address))];
			}
		}
	}
</script>

<style>
</style>
